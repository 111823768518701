import gql from 'graphql-tag';

const AUTO_SUGGEST_SIG_NAME = input => gql`
{
  topClientsByFuzzyName(_approxclientname: "${input}") {
    candidatesClient {
      clientUuid
      clientName
    }
    confidence
  }
}
`

export default AUTO_SUGGEST_SIG_NAME
