import React from 'react';
import PropTypes from 'prop-types';

export default function BoldSubstring ({
  caseSensitive,
  str: rawStr,
  substr: rawSubstr,
}) {
  //convert this array of strings into bolded or non bolded HTML
  return getPieces(caseSensitive,rawStr,rawSubstr).map((p,i) =>
    <React.Fragment key={i}>
      {i%2===1 ? (<b>{p}</b>): p}
    </React.Fragment>
  )
}

BoldSubstring.defaultProps = {
  caseSensitive: false,
  substr: "",
};

BoldSubstring.propTypes = {
  caseSensitive: PropTypes.bool,
  str: PropTypes.string.isRequired,
  substr: PropTypes.string,
};


/**
 * given the inputs, return an array of case-preserved split string including the substrings
 * so that odd indices of the array correspond to the substrings
 * @param  {Boolean} caseSensitive whether this is case sensitive
 * @param  {String} rawStr         string
 * @param  {String} rawSubstr      substring
 * @return {Array}                 array of case-preserved split string including the substrings in which odd indices of the array correspond to the substrings
 */
export function getPieces(caseSensitive,rawStr,rawSubstr) {
  const pieces = []; //will eventually hold str broken up into substrings
  let index = 0; //the index in str where substr is found
  let pos = 0; //our current search position in str

  //if rawStr is a string
  if(typeof rawStr === "string") {
    const str = caseSensitive ? rawStr : rawStr.toLowerCase() //if case insensitive, to lower case

    //if substr is not empty, search for all instances of substr in str
    if(typeof rawSubstr==="string" && rawSubstr !== "") {
      const substr = caseSensitive ? rawSubstr : rawSubstr.toLowerCase() //if case insensitive, to lower case

      //while there are still instances of substr in str
      while( (index = str.indexOf(substr, pos)) >= 0 ) {
        //use the rawStr to preserve capitalization
        pieces.push(rawStr.substring(pos, index)); //push other text before this instance of substr
        pieces.push(rawStr.substring(index, index + substr.length)) //push this instance of substr
        pos = index + substr.length //move the position
      }
    }

    //if we haven't gotten to the end of the string yet, push what's left
    if(pos < str.length) {
      pieces.push(rawStr.substring(pos)); //use the rawStr to preserve capitalization
    }
  }

  return pieces
}
