import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withApolloClient } from "utils/graphqlSetup"

import BoldSubstring from "components/boldSubstring/BoldSubstring"
import "./autoSuggestion.scss";

class AutoSuggestion extends Component {
  constructor(props) {
    super(props);
    this.state = {
      activeOption: 0, //necessary for keyboard interactions
      deactivateAllSuggestions: false,
      input: "",
      status: "",
      suggestions: [],
    };

    this.timeout = null //used to wait a little bit before making the auto suggestion request
  }

  getSuggestions = async (input) => {
    const variables = this.props.getVariables(input) //get the variables

    //clear the suggestions
    this.setState({
      activeOption: 0,
      deactivateAllSuggestions: false,
      status: "Loading...",
      suggestions: [],
    })

    //TODO if the user types a slash, ie '\', GraphQL does not like it
    try {
      this.props.client.query({
        query: this.props.getQuery(input),
        variables,
      })
      .then(this.props.processResponse).then(data => {
        //it's possible that the results coming back are already obselete, eg the user typed more letters
        //so we want to check that the results are returned for the right input
        if(input === (this.props.parentInput ? this.props.parentInput : this.state.input)) {
          this.setState({
            status: data.length>0 ? "" : "No suggestions found",
            suggestions:data,
          });
        }
      }).catch((error) => {
        console.log(error);
        this.setState({status: "There was an error completing the suggestion request"})
      });
    }
    catch(error) {
      console.log(error);
      this.setState({status: "There was an error creating the suggestion request"})
    }
  };

  clearSuggestions = () => this.setState({status: "", suggestions: []})

  changeInput = (e) => {
    clearTimeout(this.timeout) //clear any pending timeout

    const input = e.target.value;

    //if the search string meets the minimum length requirement
    if(input.length >= this.props.minInputLength) {
      //set a new timeout to make the auto suggestion request
      this.timeout = setTimeout(
        () => this.getSuggestions(input), //get the suggestions
        250, //wait 250ms
      )
    }
    else {
      this.clearSuggestions()
    }

    //if we should change the parent input
    if(this.props.parentChangeInput) {
      this.props.parentChangeInput(input) //update the parent input
    }
    else {
      this.setState({ input }) //update this input
    }
  }

  //convert str into HTML text where all instances of this.state.input are bolded (case insensitive)
  boldInput = (str) => {
    if(typeof str === "string") {
      return (
        <BoldSubstring
          str={str}
          substr={this.props.parentInput ? this.props.parentInput : this.state.input}
        />
      )
    }
  }


  keyDownEvent = (e) => {
    if (this.state.suggestions.length !== 0) {
      let option = this.state.activeOption;

      /* up arrow */
      if (e.keyCode === 40) {
        option = option + 1;
        if (option >= 1 && option <= 4) {
          this.setState({activeOption: option});
        }
      }
      /* down arrow */
      else if (e.keyCode === 38) {
        option = option - 1;
        if (option >= 0) {
          this.setState({activeOption: option});
        }
      }
      /* enter */
      else if (e.keyCode === 13) {
        e.preventDefault();
        this.setState({deactivateAllSuggestions: true})
        this.props.clickSuggestion(this, this.state.suggestions[this.state.activeOption]);
      }
    }
  }


  render() {
    const type = this.props.type || "text";

    return (
      <form style={{"width":"100%"}} onSubmit={e => e.preventDefault()} onBlur={this.clearSuggestions}>
        <div className="autoSuggestionContainer form-group">
          <div id="icon">{this.props.icon}</div>
          <input
            className="form-control"
            type={type}
            value={this.props.parentInput ? this.props.parentInput : this.state.input}
            onKeyDown={e => this.keyDownEvent(e)}
            onChange={e => this.changeInput(e)}
            placeholder={this.props.placeholder}
          />

          <div className="autoSuggestion">
            {this.state.suggestions.slice(0,this.props.maxSuggestions).map((suggestion,i) =>
              <Suggestion
                key={i}

                onClick={() => {console.log(this);this.props.clickSuggestion(this, suggestion)}}
                text={this.boldInput(this.props.getSuggestionString(suggestion))}
                isActive={i===this.state.activeOption && this.state.deactivateAllSuggestions!==true}
              />
            )}

            {this.state.status ? <SuggestionMessage text={this.state.status}/> : null}
          </div>
        </div>
      </form>
    );
  }
}

AutoSuggestion.defaultProps = {
  getVariables: () => {},
  maxSuggestions: 5,
  minInputLength: 3,
  type: "text",
}

AutoSuggestion.propTypes = {
  clickSuggestion: PropTypes.func.isRequired, //function to run when a user clicks a suggestion, ex page redirect
  client: PropTypes.object.isRequired, //graphql client
  getQuery: PropTypes.func.isRequired, //function that, given an input string, returns the graphql query
  getSuggestionString: PropTypes.func.isRequired, //function that, given a suggestion object, returns the suggestion string to bold
  getVariables: PropTypes.func, //function that, given an input string, returns the variables object
  maxSuggestions: PropTypes.number, //maximum number of suggestions to show
  minInputLength: PropTypes.number, //minimum length of search string before firing getSuggestions()
  parentChangeInput: PropTypes.func, //if provided, this function is run instead of updating this component's state.input
  parentInput: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]), //if provided, this prop is the control for the input instead of this component's state.input
  placeholder: PropTypes.string.isRequired,
  processResponse: PropTypes.func.isRequired, //function to run when the query response comes back, should return an array of suggestions (suggestions could be strings or values)
  type: PropTypes.string, //if provided, this prop sets the type of the input
};

export default withApolloClient(AutoSuggestion)







function Suggestion(props) {
//   console.log(props.isActive)
  const className = "suggestion" + (props.isActive ? " suggestion-active" : "");

  return (
    <div className={className} onMouseDown={props.onClick}>{props.text}</div>
  );
}

Suggestion.propTypes = {
  onClick: PropTypes.func.isRequired,
  text: PropTypes.object.isRequired,
  isActive: PropTypes.bool.isRequired,
}


function SuggestionMessage(props) {
  return (
    <div className="suggestion-message grayBoldText">{props.text}</div>
  )
}

SuggestionMessage.propTypes = {
  text: PropTypes.string.isRequired,
}
